@use "../abstracts/variables" as vars;
@use "../abstracts/mixins" as mix;
@use "../themes/themes" as t;
@use "sass:map";


.blurredBackground {
    display: flex;
    backdrop-filter: blur(5px);
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    animation: fadeIn .3s ease-in-out;
}


.modal {
    width: 90%;
    padding: 25px;
    text-align: center;
    background-color: map.get(vars.$colors, white);
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    border-radius: 20px;

    &__title {
        margin-bottom: 5px;
    }

    &__description {
        margin: 0 30px 20px 30px;
        color: map.get(vars.$colors, grey)
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}