@use "../abstracts/extends";
@use "../abstracts/mixins" as mix;
@use "../abstracts/variables" as vars;
@use "sass:map";

.forgot-password {
    height: 100vh;
    width: 100%;
    @include mix.gradient(135deg, vars.$pink-gradient);
    color: map.get(vars.$colors, white);
    @extend %flex-columnCenter;
    justify-content: center;
    padding-bottom: 50px;

    &__title {
        text-align: center;
    }

    a {
        color: map.get(vars.$colors, white);
    }
}