@use "../abstracts/extends";
@use "../abstracts/mixins" as mix;
@use "../abstracts/variables" as vars;
@use "../abstracts/mediamixins" as media;
@use "sass:map";

.logo-container {
    width: 100%;
    height: 225px;
    @extend %flex-fullCenter;

    @include media.small {
        height: 140px;
    }

    img {
        height: 115px;
        margin-bottom: 50px;

        @include media.small {
            height: 80px;
            margin-bottom: 40px;
        }
    }
    
}
.login {
    width: 100%;
    position: relative;
    height: calc(100vh - 225px);
    color: map.get(vars.$colors, white);
    @extend %flex-columnCenter;

    @include media.small {
        height: calc(100vh - 115px);
    }

    &__title {
        margin-bottom: 20px;

        @include media.small {
            margin-bottom: 0;
        }
    }

    a {
        color: map.get(vars.$colors, white);
    }


    &:before {
        content: '';
        @include mix.circle(900px);
        @include mix.gradient(135deg, vars.$pink-gradient);
        position: absolute;
        top: -90px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;

        @include media.small {
            top: -50px;
        }
    }

    &__forgot-password {
        display: block;
        text-align: right;
        margin-right: 20px;
    }
}