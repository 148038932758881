@use "../abstracts/variables" as vars;
@use "../themes/themes" as t;
@use "sass:map";

.form-validation {
    background-color: map.get(vars.$colors, white);
    padding: 5px 10px;
    border-radius: 10px;
    margin: -5px 20px;
    small {
        color: map.get(vars.$colors, red);
    }
}